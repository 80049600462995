'use client';
import React from 'react';
import Image from 'next/image';
import Hexagon1 from '@/assets/images/hexagon-one.png';
import Hexagon2 from '@/assets/images/hexagon-two.png';
import Hexagon3 from '@/assets/images/hexagon-three.png';
import Hexagon4 from '@/assets/images/hexagon-four.png';
import Icon1 from '@/assets/images/hexagonicon-one.png';
import Icon2 from '@/assets/images/hexagonicon-two.png';
import Icon3 from '@/assets/images/hexagonicon-four.png';
import Icon4 from '@/assets/images/hexagonicon-three.png';
import arrowIcon from '@/assets/images/arrow-icon.png';

const Card = ({ hexagon, icon, title, description }) => (
  <div className=" group relative w-[280px] perspective mb-10 ">
    <div className="relative transition-transform duration-1000 ease-in-out transform-style-3d group-hover:rotate-y-180 ">
      <div className="absolute inset-0 backface-hidden relative w-[280px] h-[280px] mx-auto flex items-center justify-center z-20 ">
        <Image
          src={hexagon}
          width={280}
          height={280}
          loading="lazy"
          placeholder="blur"
          quality={75}
          className="absolute inset-0 z-0"
          alt="Hexagon Background"
          blurDataURL={`data:image/svg+xml;base64,...`}
        />
        <div className="relative z-10 mt-12 text-center">
          <h1 className="text-2xl font-sans font-normal mb-12">{title}</h1>
          <Image src={icon} className="mx-auto" alt="Icon Illustration" />
        </div>
      </div>
      <div className="absolute inset-0 backface-hidden flex flex-col items-center justify-center rounded-md rotate-y-180 pointer-events-none z-10 group-hover:pointer-events-auto">
        <Image
          src={hexagon}
          width={280}
          height={280}
          loading="lazy"
          placeholder="blur"
          quality={75}
          blurDataURL={`data:image/svg+xml;base64,...`}
          className="absolute inset-0"
          alt="Laptop Illustration"
        />
        <div className="relative z-20 flex flex-col items-center font-sans  justify-center px-8 text-center">
          <p className="text-base mb-4">{description}</p>
          <Image src={arrowIcon} width={40} height={40} alt="Arrow Icon" />
        </div>
      </div>
    </div>
  </div>
);

const HexagonCard = () => {
  return (
    <div className="flex flex-col justify-center text-center font-sans">
      <div className="group relative mx-auto top-[12.50rem] ">
        {/* <Link href={'/LandingDetails'}> */}
        <Card
          hexagon={Hexagon1}
          icon={Icon1}
          title="Jobs & Internship"
          description="Kickstart the right career path and find your passion with Hackingly!"
        />
        {/* </Link> */}
      </div>
      <div className="flex gap-[8.50rem] ">
        {/* <Link href={'/hackathons'}> */}
        <Card
          hexagon={Hexagon4}
          icon={Icon4}
          title="Hackathons"
          description="Participate in hackathons and showcase your skills!"
        />
        {/* </Link> */}
        {/* <Link href={'/startup-funding'}> */}
        <Card
          hexagon={Hexagon2}
          icon={Icon2}
          title="Startup & Funding"
          description="Get insights and resources to fund your startup!"
        />
        {/* </Link> */}
      </div>
      <div className="group relative w-[280px] left-[13rem] bottom-[12.45rem] ">
        {/* <Link href={'/learn-upskill'}> */}
        <Card
          hexagon={Hexagon3}
          icon={Icon3}
          title="Learn & Upskill"
          description="Discover new skills and enhance your career!"
        />
        {/* </Link> */}
      </div>
    </div>
  );
};

export default HexagonCard;
