// 'use client';
// import { useState, useEffect } from 'react';
// import LandingEventsCards from './LandingEventsCards';
// import FilterPopover from './FilterPopover';
// import { BackgroundMask } from '../commons/BackgroundMask';
// import { usePathname } from 'next/navigation';

// const ExclusiveEventsCards = ({ opportunities }) => {
//   const pathname = usePathname();
//   const [filteredOpportunities, setFilteredOpportunities] = useState(opportunities);

//   const applyFilters = filters => {
//     const { opportunity_type, mode, fees, search } = filters;

//     const filtered = opportunities.filter(event => {
//       const typeMatch = opportunity_type.length === 0 || opportunity_type.includes(event.opportunity_type);
//       const modeMatch = mode.length === 0 || mode.includes(event.mode);
//       const feesMatch = fees.length === 0 || fees.includes(event.is_paid ? 'true' : 'false');
//       const searchMatch = !search || event.title.toLowerCase().includes(search.toLowerCase());

//       return typeMatch && modeMatch && feesMatch && searchMatch;
//     });

//     setFilteredOpportunities(filtered);
//   };

//   useEffect(() => {
//     setFilteredOpportunities(opportunities);
//   }, [opportunities]);

//   const isEventsPage = pathname === '/events';

//   return (
//     <div className="w-full flex flex-col items-center justify-center">
//       <BackgroundMask />
//       <div className="w-full flex flex-col items-center justify-center gap-8 md:gap-12 lg:gap-16 px-4 md:px-8 lg:px-16 xl:px-24 2xl:px-32">
//         <div className="w-full flex flex-col md:flex-row items-center justify-between gap-4">
//           <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center md:text-left">
//             {isEventsPage ? 'Events' : 'Exclusive Events'}
//           </h2>
//           {isEventsPage && <FilterPopover onFilterApply={applyFilters} />}
//         </div>
//         <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10">
//           {filteredOpportunities.map((opportunity, index) => (
//             <LandingEventsCards key={index} opportunity={opportunity} />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ExclusiveEventsCards;

import { useState } from 'react';
import LandingEventsCards from './LandingEventsCards';
import FilterPopover from './FilterPopover';
import { BackgroundMask } from '../commons/BackgroundMask';
import { usePathname } from 'next/navigation';

const ExclusiveEventsCards = ({ opportunities }) => {
  const pathname = usePathname();
  const [filteredOpportunities, setFilteredOpportunities] = useState(opportunities);

  const applyFilters = filters => {
    const { opportunity_type, mode, fees } = filters;

    const filtered = opportunities.filter(event => {
      const matchesType = !opportunity_type.length || opportunity_type.includes(event.opportunity_type);
      const matchesMode = !mode.length || mode.includes(event.mode);
      const matchesFee = !fees.length || fees.includes(event.fee === 0 ? 'Free' : 'Paid');

      return matchesType && matchesMode && matchesFee;
    });

    setFilteredOpportunities(filtered);
  };

  return (
    <BackgroundMask>
      <div className="pt-[60px] mb-4 md:my-0">
        <div className="layoutBox space-y-6 md:space-y-0">
          <div className="flex flex-col space-y-1 font-sans">
            <div className="relative flex justify-between items-center">
              <h1 className="md:text-[40px] text-lg font-medium flex-grow text-center">
                Hackingly Exclusive
              </h1>
              {pathname === '/event' && (
                <div className="absolute right-0">
                  <FilterPopover onApplyFilters={applyFilters} />
                </div>
              )}
            </div>
            <div className="md:pt-4 md:tracking-wide tracking-wide md:text-xl text-sm text-[#4F4F4F] text-center">
              Explore The Diverse Range Of Exclusive Events
            </div>
          </div>
          <LandingEventsCards opportunities={filteredOpportunities} />
        </div>
      </div>
    </BackgroundMask>
  );
};

export default ExclusiveEventsCards;
