import React from 'react';
// import { TbUserCode } from 'react-icons/tb';
// import { FaCode, FaRegLightbulb } from 'react-icons/fa';
import { Card, CardBody } from '@nextui-org/react';
import { BackgroundMask } from '../commons/BackgroundMask';

const Stats = () => {
  return (
    <BackgroundMask>
      <div className="py-20  md:space-y-10 space-y-10">
        <h2 className="md:text-[40px]  font-medium text-lg font-sans text-center ">
          The Impact We Make
        </h2>

        <div className="flex md:flex-wrap  justify-center gap-5 ">
          {numbers?.map((item, index) => (
            <Card key={index} shadow="none" className="md:w-80 p-2 ">
              <CardBody className="flex md:flex-col items-center  text-center p-0">
                {/* Icon */}
                {/* <span className="text-6xl text-primary">{item.icon}</span> */}
                {/* Title */}
                <h1 className="md:text-[40px] font-sans font-bold bg-gradient-to-r from-emeraldGreen to-darkGreen bg-clip-text text-transparent">
                  {item.num}
                </h1>
                <h3 className="md:text-xl font-sans text-bold ">
                  {item.title}
                </h3>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>
    </BackgroundMask>
  );
};

export default Stats;
let numbers = [
  {
    // icon: <TbUserCode />,
    num: '50k+',
    title: 'Developers Associated',
  },
  {
    // icon: <FaCode />,
    num: '500+',
    title: 'Hackathons & bootcamps',
  },
  {
    // icon: <FaRegLightbulb />,
    num: '50+',
    title: 'Ideas Funded',
  },
];
