// import { Button } from '@nextui-org/react';
// import { useState, useEffect } from 'react';
// const CTAButton = ({
//   children,
//   onClick,
//   className = '',
//   size = 'lg',
//   variant = 'ghost',
//   color = 'none',
// }) => {
//   const [isClient, setIsClient] = useState(false);
//   useEffect(() => {
//     setIsClient(true);
//   });
//   return ( {
//     isClient ?
//     <Button
//       onClick={onClick}
//       size={size}
//       variant={variant}
//       color={color}
//       className={`bg-[#097969] text-white border-[#097969] border-1 shadow-inner-glow font-sans hover:bg-[#005D44] hover:shadow-inner-glow ${className}`}
//     >
//       {children}
//     </Button>
//   }
//     <Button
//       onClick={onClick}
//       size={size}
//       variant={variant}
//       color={color}
//       className={`bg-[#097969] text-white border-[#097969] border-1 shadow-inner-glow font-sans hover:bg-[#005D44] hover:shadow-inner-glow ${className}`}
//     >
//       {children}
//     </Button>
//   );
// };

// export default CTAButton;
'use client';
import Link from 'next/link';
import { Button } from '@nextui-org/react';

const CTAButton = ({
  children,
  href,
  onClick,
  className = '',
  size = 'lg',
  variant = 'ghost',
  color = 'none',
}) => {
  // If `href` is provided, wrap the button with a Link
  if (href) {
    return (
      <Link href={href} passHref>
        <Button
          size={size}
          variant={variant}
          color={color}
          className={`bg-[#097969] text-white border-[#097969] border-1 shadow-inner-glow font-sans hover:bg-[#005D44] hover:shadow-inner-glow ${className}`}
        >
          {children}
        </Button>
      </Link>
    );
  }

  // Render a normal button if `href` is not provided
  return (
    <Button
      onClick={onClick}
      size={size}
      variant={variant}
      color={color}
      className={`bg-[#097969] text-white border-[#097969] border-1 shadow-inner-glow font-sans hover:bg-[#005D44] hover:shadow-inner-glow ${className}`}
    >
      {children}
    </Button>
  );
};

export default CTAButton;
