'use client';
import React from 'react';
import CTAButton from '../commons/CTAButton';

const CommunityCTA = () => {
  return (
    <div
      className="flex items-center justify-center md:py-16 py-5 rounded-3xl"
      style={{
        background:
          'linear-gradient(90deg, #E2FFF4 0%, #EBFBF3 49.77%, #FFF5F2 100%)',
      }}
    >
      <div className="flex justify-center flex-col text-center space-y-8 items-center">
        <h2 className="md:text-4xl font-medium font-sans ">
          Become a part of our{' '}
          <span className="bg-custom-gradient text-transparent bg-clip-text">
            Community
          </span>{' '}
          and unlock <br /> your skills!
        </h2>

        {/* Desktop Button */}
        <CTAButton
          href="https://chat.whatsapp.com/Kv681piogRgLxMXXqUtKCS"
          className="hidden md:block px-14 box-content  rounded-2xl"
        >
          Join Community
        </CTAButton>

        {/* Mobile Button */}
        <CTAButton
          size="sm"
          href="https://chat.whatsapp.com/Kv681piogRgLxMXXqUtKCS"
          className="md:hidden text-xs md:text-md md:px-12 md:py-7 mt-3"
        >
          Join Community
        </CTAButton>
      </div>
    </div>
  );
};

export default CommunityCTA;
