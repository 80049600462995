'use client';
import { useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Input,
} from '@nextui-org/react';
import Image from 'next/image';
import filter from '@/assets/svgs/filter.svg';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import axios from 'axios';

const FilterPopover = ({ opportunities, onApplyFilters }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    opportunity_type: [],
    mode: [],
    fees: [],
  });

  // let updatedFilters = { ...selectedFilters };

  // Function to toggle filter selection
  const handleFilterChange = (category, value) => {
    setSelectedFilters(prev => ({
      ...prev,

      [category]: prev[category].includes(value)
        ? prev[category].filter(item => item !== value)
        : [...prev[category], value],
    }));
  };

  const handleApplyFilters = () => {
    onApplyFilters(selectedFilters);
  };

  const handleClearFilters = (category, value) => {
    setSelectedFilters(prev => ({
      ...prev,
      [category]: [],
      [value]: [],
    }));
  };

  // Simulate filtering the events by sending API call with selected filters
  const filterEvents = async () => {
    const params = new URLSearchParams();

    const addParams = (key, values) => {
      if (values.length) {
        values.forEach(value => params.append(key, value));
      }
    };

    addParams('opportunity_type', selectedFilters.opportunity_type);
    addParams('mode', selectedFilters.mode);
    addParams(
      'fees',
      selectedFilters.fees.map(fee => (fee === 'Paid' ? '1' : '0'))
    );

    console.log('API Params:', params.toString());

    try {
      const response = await axios
        .get(`${baseURL}/opportunity/list/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        })
        .then(response => {
          se;
        });
      console.log('Filtered Events:', response.data);
    } catch (error) {
      console.error('Error fetching filtered events:', error);
    }
  };

  // Define filters
  const filters = [
    {
      id: 'opportunity_type',
      name: 'Event Type',
      options: [
        { value: 'Hackathon', label: 'Hackathon' },
        { value: 'Bootcamp', label: 'Bootcamp' },
        { value: 'Workshop', label: 'Workshop' },
      ],
    },
    {
      id: 'mode',
      name: 'Mode',
      options: [
        { value: 'Online', label: 'Online' },
        { value: 'In Person', label: 'Offline' },
      ],
    },
    {
      id: 'fees',
      name: 'Registration Fee',
      options: [
        { value: 'false', label: 'Free' },
        { value: 'true', label: 'Paid' },
      ],
    },
  ];

  return (
    <Popover placement="bottom">
      <PopoverTrigger>
        <Button
          className="md:px-8 bg-white text-black font-medium text-base lg:text-lg py-4"
          variant="bordered"
          size="lg"
          startContent={
            <Image src={filter} alt="filter" width={20} height={20} />
          }
        >
          FILTER
        </Button>
      </PopoverTrigger>

      <PopoverContent variant="bordered" className="p-6 space-y-4 lg:w-[450px]">
        {/* Search Bar */}
        <div className="flex justify-between font-medium w-full text-lg ">
          <div className="text-lg">Search by Keywords</div>
          <button
            className="text-green-900"
            onClick={() => handleClearFilters('search')}
          >
            Clear
          </button>
        </div>
        <Input
          placeholder="Search.."
          size="lg"
          className="w-full text-lg "
          startContent={
            <FaMagnifyingGlass className="text-[#757575]" size={24} />
          }
        />

        {/* Filters */}
        {filters.map(filter => (
          <div key={filter.id} className="space-y-3 w-full">
            <div className="flex justify-between w-full text-lg">
              <div>{filter.name}</div>
              <button
                className="text-green-900"
                onClick={() => handleClearFilters(filter.id)}
              >
                Clear
              </button>
            </div>
            <div className="grid grid-cols-3 gap-3 w-full">
              {filter.options.map(option => (
                <Button
                  key={option.value}
                  variant="bordered"
                  radius="full"
                  size="lg"
                  onClick={() => handleFilterChange(filter.id, option.value)}
                  className={`text-sm w-full ${
                    selectedFilters[filter.id].includes(option.value)
                      ? 'bg-green-600 text-white'
                      : 'text-gray-500'
                  }`}
                >
                  {option.label}
                </Button>
              ))}
            </div>
          </div>
        ))}

        {/* Action Buttons */}
        <div className="flex justify-between w-full gap-4 py-4 mt-4">
          <Button
            variant="bordered"
            radius="3xl"
            size="lg"
            className="text-sm border-green-900 text-black text-lg flex-1"
            onClick={() =>
              setSelectedFilters({
                opportunity_type: [],
                mode: [],
                fees: [],
              })
            }
          >
            Cancel
          </Button>

          <Button
            variant="ghost"
            radius="3xl"
            size="lg"
            className="text-sm border-green-900 text-black bg-[#097969] text-lg flex-1 text-white border-[#097969] border-1 shadow-inner-glow font-sans hover:bg-[#005D44] hover:shadow-inner-glow"
            onClick={handleApplyFilters}
          >
            Apply Now
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default FilterPopover;
